import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseurl, SettingsApiEndPoint } from "../../configs/serverApiConfig";

export const GetSettings = createAsyncThunk(
  "/CMS/GetSettings",
  async (thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${SettingsApiEndPoint.Get}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getSettings: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetSettingsBySlug = createAsyncThunk(
  "/CMS/GetSettingsBySlug",
  async (id,thunkAPI) => {
    // console.log(id, "id");
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${SettingsApiEndPoint.GetByCode}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getSettingsBySlug: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddSettings = createAsyncThunk(
  "/CMS/AddSettings",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${baseurl?.adminServiceBaseURl}${SettingsApiEndPoint.Add}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addSettings: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpDateSettings = createAsyncThunk(
  "/CMS/UpDateSettings",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.put(
        `${baseurl?.adminServiceBaseURl}${SettingsApiEndPoint.Update}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { upDateSettings: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteSettings = createAsyncThunk(
  "/CMS/DeleteSettings",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.delete(
        `${baseurl?.adminServiceBaseURl}${SettingsApiEndPoint.Delete}/${data}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { deleteSettings: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
