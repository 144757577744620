import { createAsyncThunk } from "@reduxjs/toolkit";
// import { handleLogout } from "@store/auth/authSlice";

import axios from "axios";
import {
  baseurl,
  companyManagementApiEndPoint,
  escrowApiEndPoint,
} from "../../configs/serverApiConfig";

const accessToken = localStorage.getItem("accessToken");

export const GetCompanyByAdmin = createAsyncThunk(
  "companyMangeTableData/GetCompanyByAdmin",
  async (_, thunkAPI) => {
    // const { dispatch } = thunkAPI;
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${baseurl.customerBaseURL}${companyManagementApiEndPoint.GetCompanyByAdmin}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response?.data, "response");

      return { companyManageData: response?.data };
    } catch (error) {
      // if (error.response?.status === 401) {
      //   dispatch(handleLogout());
      // }
      // console.log(, "error");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetCompanyById = createAsyncThunk(
  "companyMangeTableData/GetCompanyById",
  async (id, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${baseurl.customerBaseURL}${companyManagementApiEndPoint.GetCompanyList}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response?.data, "response");

      return { getCompanyById: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetCompanyUserByID = createAsyncThunk(
  "companyMangeTableData/GetCompanyUserByID",
  async (id, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${baseurl.customerBaseURL}${companyManagementApiEndPoint.GetCompanyUserList}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getCompanyUserByID: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const CreateUserCompany = createAsyncThunk(
  "/user/createUser",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${baseurl?.customerBaseURL}${companyManagementApiEndPoint.CreateUser}/${data?.id}`,
        data?.dataPayLoad,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addUser: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const EditUserCompany = createAsyncThunk(
  "/user/EditUserCompany",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.put(
        `${baseurl?.customerBaseURL}${companyManagementApiEndPoint.EditUser}/${data?.virtualIdCompany}`,
        data?.data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addUser: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const EditCompany = createAsyncThunk(
  "/user/EditCompany",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.put(
        `${baseurl?.customerBaseURL}${companyManagementApiEndPoint.UpdateCompany}/${data?.virtualIdCompany}`,
        data?.updatedCompanyDetails,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { editCompany: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetActiveInActiveUSer = createAsyncThunk(
  "companyMangeTableData/GetActiveInActiveUSer",
  async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${baseurl.customerBaseURL}${companyManagementApiEndPoint.GetActiveInActiveUser}/${data?.virtualId}/${data?.status}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getActiveInActiveUSer: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetBranchData = createAsyncThunk(
  "escrow/getBranchData",
  async (data, thunkAPI) => {
    console.log(data, "dataEscrow");
    try {
      const response = await axios.post(
        `${baseurl.paymentBaseURL}${escrowApiEndPoint?.GetBranchData}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { getBranchData: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const AddEscrowBank = createAsyncThunk(
  "escrow/AddEscrowBank",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.loanBaseURL}${escrowApiEndPoint.AddEscrowPayBankAccount}/${data?.id}`,
        data?.AddBankPayLoad,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addEscrowBank: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetEscrowAccountStatement = createAsyncThunk(
  "escrow/GetEscrowAccountStatement",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.paymentBaseURL}${escrowApiEndPoint.GetEscrowPayStatement}/${data?.virtualId}`,
        data?.pagination,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { getEscrowAccountStatement: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetEscrowBank = createAsyncThunk(
  "escrow/GetEscrowBank",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${escrowApiEndPoint.GetEscrowBank}/${id}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { getEscrowBank: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
