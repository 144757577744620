// ** Core JWT Import
// import useJwt from '@src/@core/auth/jwt/useJwt'

// const { jwt } = useJwt({})

// export default jwt


import useJwt from '@src/@core/auth/jwt/useJwt'

const JwtEmptyConfig = ()=>{

    const { jwt } = useJwt({})
    return jwt.jwtConfig;

}

export default JwtEmptyConfig;



