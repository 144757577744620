import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  attachmentApiEndPoint,
  baseurl,
  loanOfferApiEndPoint,
} from "../../configs/serverApiConfig";

export const UploadFileThumbnail = createAsyncThunk(
  "/Admin/UploadFileThumbnail",
  async (UploadFiledata, thunkAPI) => {
    try {
      const apiurl = process.env.REACT_APP_API_BASEURL;
      const formData = new FormData();
      formData.append("file", UploadFiledata);
      const response = await axios.post(
        `${apiurl}${attachmentApiEndPoint.UploadFile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type explicitly
          },
        }
      );
      return { uploadFileThumbnail: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetLoanOffer = createAsyncThunk(
  "/OfferRules/GetLoanOffer",
  async (thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${loanOfferApiEndPoint.list}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { getLoanOfferData: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetCibilList = createAsyncThunk(
  "/OfferRules/GetCibilList",
  async (thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${loanOfferApiEndPoint.cibilList}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { getCibilList: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetLoanOfferById = createAsyncThunk(
  "/OfferRules/id",
  async (id,thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");
    const data = id.id;
    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${loanOfferApiEndPoint.OfferRules}/${data}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanOfferById: response };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddLoanOffer = createAsyncThunk(
  "/OfferRules/AddLoanOffer",
  async (data, thunkAPI) => {
    try {
      // const apiurl = process.env.REACT_APP_API_BASEURL;
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${baseurl?.adminServiceBaseURl}${loanOfferApiEndPoint.Add}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addLoanOffer: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const AddCibilRules = createAsyncThunk(
  "/OfferRules/AddCibilRules",
  async (data, thunkAPI) => {
    try {
      // const apiurl = process.env.REACT_APP_API_BASEURL;
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${baseurl?.adminServiceBaseURl}${loanOfferApiEndPoint.AddCibil}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addCibilRules: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpDateLoanOffer = createAsyncThunk(
  "/OfferRules/Update",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const id = data.id;
      const response = await axios.put(
        `${baseurl?.adminServiceBaseURl}${loanOfferApiEndPoint.Update}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data' // Set the content type explicitly
          },
        }
      );
      return { upDateLoanOffer: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteLoanOffer = createAsyncThunk(
  "/CMS/DeleteLoanOffer",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.delete(
        `${baseurl?.adminServiceBaseURl}${loanOfferApiEndPoint.DeleteOfferRules}/${data}`,
        {
          data: { id: data }, // Pass the id in the request body
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { deleteLoanOffer: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
