import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseurl, BankApiEndPoint } from "../../configs/serverApiConfig";

export const GetBank = createAsyncThunk("/CMS/GetBank", async (thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    const response = await axios.get(
      `${baseurl?.adminServiceBaseURl}${BankApiEndPoint.Get}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return { getBank: response?.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const GetBankBySlug = createAsyncThunk(
  "/CMS/GetBankBySlug",
  async (id,thunkAPI) => {
    // console.log(id, "id");
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${BankApiEndPoint.GetById}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getBankBySlug: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddBank = createAsyncThunk(
  "/CMS/AddBank",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${baseurl?.adminServiceBaseURl}${BankApiEndPoint.Add}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addBank: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpDateBank = createAsyncThunk(
  "/CMS/UpDateBank",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.put(
        `${baseurl?.adminServiceBaseURl}${BankApiEndPoint.Update}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { upDateBank: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteBank = createAsyncThunk(
  "/CMS/DeleteBank",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.delete(
        `${baseurl?.adminServiceBaseURl}${BankApiEndPoint.Delete}/${data}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { deleteBank: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
