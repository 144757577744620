export const accessToken = localStorage.getItem("accessToken");

export const baseurl = {
  authBaseURL: "https://api.moneyplant.finance/v1/auth/",
  adminServiceBaseURl: "https://api.moneyplant.finance/v1/admin/",
  customerBaseURL: "https://api.moneyplant.finance/v1/customer/",
  loanBaseURL: "https://api.moneyplant.finance/v1/loan/",
  paymentBaseURL: "https://api.moneyplant.finance/v1/payment/",
  kycBaseURL: "https://api.moneyplant.finance/v1/kyc/",
  employeeManagementBaseURL: process.env.REACT_APP_API_BASEURL,
  companyManagementBaseURL: process.env.REACT_APP_API_BASEURL,
  loanManagementBaseBaseURL: process.env.REACT_APP_API_BASEURL,
  commissionManagementBaseUrl: process.env.REACT_APP_API_BASEURL,
  adminProfileManagementBaseURL: process.env.REACT_APP_API_BASEURL,
};

//const ip = "192.168.1.6"
// const ip = "13.233.170.187"

// export const baseurl = {
//   authBaseURL: `http://${ip}:8081/v1/auth/`,
//   adminServiceBaseURl: `http://${ip}:8086/v1/admin/`,
//   customerBaseURL: `http://${ip}:8082/v1/customer/`,
//   loanBaseURL: `http://${ip}:8083/v1/loan/`,
//   paymentBaseURL: `http://${ip}:8084/v1/payment/`,
//   kycBaseURL: `http://${ip}:8085/v1/kyc/`,
//   employeeManagementBaseURL: process.env.REACT_APP_API_BASEURL,
//   companyManagementBaseURL: process.env.REACT_APP_API_BASEURL,
//   loanManagementBaseBaseURL: process.env.REACT_APP_API_BASEURL,
//   commissionManagementBaseUrl: process.env.REACT_APP_API_BASEURL,
//   adminProfileManagementBaseURL: process.env.REACT_APP_API_BASEURL,
// };

export const authApiEndPoint = {
  adminSignIn: "admin-signin",
};
export const adminProfileApiEndPoint = {
  GetProfile: "admin/get-profile",
  UpdateAdminProfile: "admin/edit-profile",
  ChangePassword: "admin/change-password",
  ForgotPassword: "admin/forgot-password",
  ForgotPasswordWithToken: "admin/forgot-password-with-token",
};

export const dashboardApiEndPoint = {
  GetAllEmiByMissing: "admin/admin-pending-emi",
  GetNotificationByUser: "Notification/GetNotificationByUser",
  ReadNotification: "Notification/ReadNotification",
  EarningReportEnd: "admin/admin-earning-report",
};
export const companyManagementApiEndPoint = {
  GetCompanyByAdmin: "admin/admin-company-list",
  GetCompanyList: "admin/get-company-details-by-virtualId",
  GetCompanyUserList: "admin/company-user-list",
  CreateUser: "admin/create-company-user",
  EditUser: "admin/update-company-user",
  UpdateCompany: "admin/update-company",
  GetActiveInActiveUser: "admin/active-inactive-company-user",
};
export const attachmentApiEndPoint = {
  UploadFile: "uploadfile",
};

export const employeeManagementApiEndPoint = {
  GetAllUser: "User/GetAllUser",
  GetAllUserById: "User/GetAllUserById",
  GetLoanDetailsByUser: "Loan/GetLoanDetailsByUser", // EmployeeLoanDetails
  GetNotificationByUserId: "Notification/GetNotificationByUserId",
  GetTransactionByUserId: "transaction/GetTransactionByUserId",
  GetEmiCalenderByUser: "Loan/GetEmiCalenderByUser",
};

export const loanManagementApiEndPoint = {
  GetLoanByReport: "Loan/GetLoanByReport",
  GetTransactionByUserId: "transaction/GetTransactionByUserId", //GetEMIHistory
  GetEmiCalenderByLoanAndUser: "Loan/GetEmiCalenderByLoanAndUser",
  GetTransactionByUserIdAndLoan: "transaction/GetTransactionByUserIdAndLoan",
  GetAllLoanByAdmin: "Loan/GetAllLoanByAdmin",
  GetLoanList: "admin/loan-list",
  GetLoanDetailsByLoanId: "admin/loan-details",
  GetLoanEMIHistoryEnd: "admin/emi-history",
  GetLoanEMICalenderEnd: "emi-calendar",
  GetLoanAccountNumberById: "admin/get-loan-numbers-by-loan",
};

export const commissionManagementApiEndPoint = {
  GetCommission: "Loan/GetCommission",
  GetLoanByCompany: "Loan/GetLoanByCompany",
};

export const reportApiEndPoint = {
  GetLoanByReport: "admin/loan-report",
  GetEmiByReport: "admin/emi-report",
  GetCommission: "admin/commission-report",
};

export const cmsApiEndPoint = {
  GetCMS: "cms/list",
  GetCMSBySlug: "cms/get-cms-by-id",
  UpdateCms: "cms/update",
  AddCMS: "cms/create",
  DeleteCMS: "cms/delete",
};

export const blogApiEndPoint = {
  Get: "blog/list",
  GetBlogBySlug: "blog/get-blog-by-id",
  Add: "blog/create",
  Update: "blog/update",
  DeleteBlog: "blog/delete",
};
export const AdvertisementApiEndPoint = {
  Get: "ads/list",
  GetAdvertisementBySlug: "ads/get-ads-by-id",
  Add: "ads/create",
  Update: "ads/update",
  DeleteAdvertisement: "ads/delete",
};
export const newsApiEndPoint = {
  Get: "news/list",
  GetNewsBySlug: "news/get-news-by-id",
  Add: "news/create",
  Update: "news/update",
  DeleteNews: "news/delete",
};

export const LendingPartnersApiEndPoint = {
  Get: "lending-partners/list",
  GetLendingPartnersById: "lending-partners/get-lending-partners-by-id",
  Add: "lending-partners/create",
  Update: "lending-partners/update",
  DeleteLendingPartners: "lending-partners/delete",
};

export const notificationApiEndPoint = {
  SendNotificationByEmail: "Notification/SendNotificationByEmail",
  BulkNotificationByUser: "Notification/BulkNotificationByUser",
  SendNotificationBySMS: "Notification/SendNotificationBySMS",
};

export const loanOfferApiEndPoint = {
  cibilList: "risk-segment-rules/cibil-rules-list",
  list: "risk-segment-rules/segment-rules-list",
  OfferRules: "OfferRules",
  Add: "risk-segment-rules/create-segment-rules-list",
  AddCibil: "risk-segment-rules/create-cibil-rules-list",
  Update: "OfferRules/Update",
  DeleteOfferRules: "risk-segment-rules/delete",
};

export const SettingsApiEndPoint = {
  Add: "settings/create",
  Get: "settings/list",
  GetByCode: "settings/get-setting-by-code",
  Update: "settings/update",
  Delete: "settings/delete",
};
export const BankApiEndPoint = {
  Add: "bank/create",
  Get: "bank/list",
  Update: "bank/update",
  GetById: "bank/get-bank-by-id",
  Delete: "bank/delete",
};

export const escrowApiEndPoint = {
  GetBranchData: "admin/get-branchdata",
  AddEscrowPayBankAccount: "admin/add-escrowpay-bank-account",
  GetEscrowPayStatement: "admin/get-escrowpay-statement",
  DepositMoney: "admin/get-escrowpay-payment-url",
  GetEscrowBank: "admin/get-bank",
};
