import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./companyManagementAction";

export const companyManageDataSlice = createSlice({
  name: "companyManageTableData",
  initialState: {
    companyManageData: [],
    getCompanyById: [],
    getCompanyUserByID: [],
    isLoading: false,
    isLoadingEscrowStatement: false,
    isLoadingCompanyDetails: false,
    isError: false,
    isLoadingEscrowBank: false,
    getActiveInActiveUSer: [],
    getEscrowBank: [],
  },
  reducers: {
    resetCompanyError: (state) => {
      state.isError = false;
    },
    resetGetCompany: (state) => {
      state.getCompanyById = [];
    },
    resetEscrowBankData: (state) => {
      state.getEscrowBank = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetCompanyByAdmin.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(actions.GetCompanyByAdmin.fulfilled, (state, action) => {
        state.companyManageData = action.payload.companyManageData;
        state.isLoading = false;
      })
      .addCase(actions.GetCompanyByAdmin.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(actions.GetCompanyById.pending, (state) => {
        state.isLoadingCompanyDetails = true;
        state.isError = false;
      })
      .addCase(actions.GetCompanyById.fulfilled, (state, action) => {
        state.getCompanyById = action.payload.getCompanyById;
        state.isLoadingCompanyDetails = false;
      })
      .addCase(actions.GetCompanyById.rejected, (state) => {
        state.isLoadingCompanyDetails = false;
        // state.isError = true;
      })
      .addCase(actions.GetCompanyUserByID.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(actions.GetCompanyUserByID.fulfilled, (state, action) => {
        state.getCompanyUserByID = action.payload.getCompanyUserByID;
        state.isLoading = false;
      })
      .addCase(actions.GetCompanyUserByID.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(actions.CreateUserCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.CreateUserCompany.fulfilled, (state, action) => {
        state.createUSerData = action.payload.data;
        state.isLoading = false;
      })
      .addCase(actions.CreateUserCompany.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(actions.EditUserCompany.fulfilled, (state, action) => {
        state.editUSerData = action.payload.data;
        // state.loading = false;
      })
      .addCase(actions.EditUserCompany.rejected, (state, action) => {
        state.isError = action.error.message;
        state.isLoading = false;
      })
      .addCase(actions.EditCompany.fulfilled, (state, action) => {
        state.editCompany = action.payload.data;
      })
      .addCase(actions.EditCompany.rejected, (state, action) => {
        state.isError = action.error.message;
        state.isLoading = false;
      })
      .addCase(actions.GetActiveInActiveUSer.fulfilled, (state, action) => {
        state.getActiveInActiveUSer = action.payload.data;
      })
      .addCase(actions.GetBranchData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.GetBranchData.fulfilled, (state, action) => {
        state.getBranchData = action.payload?.getBranchData;
        state.isLoading = false;
      })
      .addCase(actions.GetBranchData.rejected, (state, action) => {
        state.isError = action.error.message;
        state.isLoading = false;
      })
      .addCase(actions.AddEscrowBank.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.AddEscrowBank.fulfilled, (state, action) => {
        state.addEscrowBank = action.payload?.addEscrowBank;
        state.isLoading = false;
      })
      .addCase(actions.AddEscrowBank.rejected, (state, action) => {
        state.isError = action.error.message;
        state.isLoading = false;
      })
      .addCase(actions.GetEscrowAccountStatement.pending, (state) => {
        state.isLoadingEscrowStatement = true;
      })
      .addCase(actions.GetEscrowAccountStatement.fulfilled, (state, action) => {
        state.getEscrowAccountStatement =
          action.payload?.getEscrowAccountStatement;
        state.isLoadingEscrowStatement = false;
      })
      .addCase(actions.GetEscrowAccountStatement.rejected, (state, action) => {
        state.isError = action.error.message;
        state.isLoadingEscrowStatement = false;
      })
      .addCase(actions.GetEscrowBank.pending, (state) => {
        state.isLoadingEscrowBank = true;
      })
      .addCase(actions.GetEscrowBank.fulfilled, (state, action) => {
        state.getEscrowBank = action.payload?.getEscrowBank;
        state.isLoadingEscrowBank = false;
      })
      .addCase(actions.GetEscrowBank.rejected, (state, action) => {
        state.isError = action.error.message;
        state.isLoadingEscrowBank = false;
      });
  },
});
export const { resetCompanyError } = companyManageDataSlice.actions;
export const { resetGetCompany } = companyManageDataSlice.actions;
export const { resetEscrowBankData } = companyManageDataSlice.actions;
export default companyManageDataSlice.reducer;
