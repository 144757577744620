import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  attachmentApiEndPoint,
  baseurl,
  blogApiEndPoint,
} from "../../configs/serverApiConfig";

export const GetBlog = createAsyncThunk("/CMS/GetBlog", async (thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    const response = await axios.get(
      `${baseurl?.adminServiceBaseURl}${blogApiEndPoint.Get}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return { getBlog: response?.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const GetBlogBySlug = createAsyncThunk(
  "/CMS/GetBlogBySlug",
  async (data,thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${blogApiEndPoint.GetBlogBySlug}/${data?.slug}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getBlogBySlug: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddBlog = createAsyncThunk(
  "/CMS/AddBlog",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${baseurl?.adminServiceBaseURl}${blogApiEndPoint.Add}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data' // Set the content type explicitly
          },
        }
      );
      return { addBlog: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpDateBlog = createAsyncThunk(
  "/CMS/UpDateBlog",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.put(
        `${baseurl?.adminServiceBaseURl}${blogApiEndPoint.Update}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { upDateBlog: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteBlog = createAsyncThunk(
  "/CMS/DeleteBlog",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.delete(
        `${baseurl?.adminServiceBaseURl}${blogApiEndPoint.DeleteBlog}/${data}`,
        {
          data: { id: data }, // Pass the id in the request body
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { deleteBlog: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UploadFileThumbnail = createAsyncThunk(
  "/Admin/UploadFileThumbnail",
  async (UploadFiledata, thunkAPI) => {
    try {
      const apiurl = process.env.REACT_APP_API_BASEURL;
      const formData = new FormData();
      formData.append("file", UploadFiledata);
      const response = await axios.post(
        `${apiurl}${attachmentApiEndPoint.UploadFile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type explicitly
          },
        }
      );
      return { uploadFileThumbnail: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
